import React from 'react';
import { ChakraProvider, AspectRatio, Text, Center } from '@chakra-ui/react';

import theme from '../../theme';

import Header from '../../components/Header';
import TableDash from '../../components/TableDash';
import About from '../../components/About';
import Products from '../../components/Products';
import Footer from '../../components/Footer';
import DividerHeader from '../../components/DividerHeader';
import FaqSection from '../../components/FaqSection';
import Gallary from '../../components/Gallary';
import ScrollToTop from '../../components/ScrollToTop';
import Schedule from '../../components/Schedule';

const MainPage = () => (
  <ChakraProvider theme={theme}>
    <Header />
    <About />
    <DividerHeader name="Наши продукты :" id="products" />
    <Products />
    <DividerHeader name="О продукции :" id="aboutProducts" />
    <Gallary />
    <DividerHeader name="Цены :" id="price" />
    <TableDash />
    <Center>
      <Text m={20} fontSize={'20'} as="i" fontWeight={700} color={'yellow.500'}>
        {' '}
        Уважаемые покупатели при покупке любой нашей продукции погрузка
        осуществляется нашими грузчиками в ваше транспортное средство.БЕСПЛАТНО.{' '}
      </Text>
    </Center>
    <DividerHeader name="Частые Вопросы :" id="faq" />
    <FaqSection />
    <DividerHeader name="График работы:" id="faq" />
    <Schedule/>
    <DividerHeader name="Где нас найти ?" id="contacts" />
    <DividerHeader
      name="Мы находимся по адресу: улица Перова 43 корпус 9"
      id={undefined}
    />
    <AspectRatio ratio={16 / 6}>
      <iframe
        src="https://yandex.ru/map-widget/v1/?um=constructor%3Aff37db46466e1aaed5b6fe1ed5eac90e61f9911f48669eeb429e5a0554b7a0af&amp;source=constructor"
        width="737"
        height="447"
        frameBorder="0"
      ></iframe>
    </AspectRatio>
    <ScrollToTop />

    <Footer />
  </ChakraProvider>
);

export default MainPage;
