import { Spinner, Center } from '@chakra-ui/react';

const Loading = () => {
  return (
    <Center height="100vh">
      <Spinner
        size="xl"
        color="yellow.500"
      ></Spinner>
    </Center>
  );
};
export default Loading;
