import React, { useState, useEffect } from 'react';
import {
  ChakraProvider,
  Container,
  SimpleGrid,
  Button,
} from '@chakra-ui/react';
import theme from '../../theme';
import { ArrowBackIcon } from '@chakra-ui/icons';

import Header from '../../components/Header';
import imageOne from '../../assets/img/cow2.jpg';

import CardProduct from '../../components/CardProduct.js';

import Footer from '../../components/Footer';
import DividerHeader from '../../components/DividerHeader';


import Loading from '../../components/Loading';
import axios from 'axios';
import serverURL from '../../services/serverURL.jsx';

const BMVKPage = () => {
  const [forbmwk, setForbmwk] = useState<any>(null);

  // Функция для выполнения GET-запроса к серверу и получения данных о товарах
  const fetchForbmwk = async () => {
    try {
      const response = await axios.get(`${serverURL}/forbmwk`);
      setForbmwk(response.data);
    } catch (error) {
      console.error('Ошибка при получении данных:', error);
    }
  };

  useEffect(() => {
    fetchForbmwk();
  }, []);

  if (!forbmwk) {
    return <Loading></Loading>;
  }
  return (
    <ChakraProvider theme={theme}>
      <Header />
      <Button m={'3'} href={'/'} cursor={'pointer'} as={'a'}>
        <ArrowBackIcon boxSize={10} color={'yellow.500'} />
      </Button>
      <Container maxW={'full'} h={'80vh'} centerContent>
        <DividerHeader name="Премиксы и БМВК" id="contacts" />

        <SimpleGrid columns={[1, 1, 1, 1, 1, 1]} spacing="40px">
          <CardProduct
            title="БВМК"
            optionTitle="Для дойных коров"
            subtitle={`Мешки ${forbmwk.weight}`}
            description="Подробнее..."
            price={forbmwk.price}
            image={imageOne}
            href={'/bmvkdes'}
          />
        </SimpleGrid>
      </Container>
      <Footer />
    </ChakraProvider>
  );
};

export default BMVKPage;
