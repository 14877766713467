import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import theme from '../../theme';

import { ArrowBackIcon } from '@chakra-ui/icons';

import Header from '../../components/Header';

import image from '../../assets/img/extrud1.jpg';

import {
  Box,
  Container,
  Stack,
  Text,
  Image,
  Flex,
  VStack,
  Button,
  Heading,
  SimpleGrid,
  StackDivider,
  useColorModeValue,
} from '@chakra-ui/react';

import Footer from '../../components/Footer';

const ExtrudedKormPage = () => {
  return (
    <ChakraProvider theme={theme}>
      <Header />
      <Button m={'3'} href={'/extruded'} cursor={'pointer'} as={'a'}>
        <ArrowBackIcon boxSize={10} color={'yellow.500'} />
      </Button>
      <Container maxW={'full'} h={'380vh'} centerContent>
        <Container maxW={'7xl'}>
          <SimpleGrid
            columns={{ base: 1, lg: 2 }}
            spacing={{ base: 8, md: 10 }}
            py={{ base: 18, md: 24 }}
          >
            <Flex>
              <Image
                rounded={'md'}
                alt={'product image'}
                src={image}
                fit={'cover'}
                align={'center'}
                w={'100%'}
                h={{ base: '100%', sm: '400px', lg: '500px' }}
              />
            </Flex>
            <Stack spacing={{ base: 6, md: 10 }}>
              <Box as={'header'}>
                <Heading
                  lineHeight={1.1}
                  fontWeight={600}
                  color={'yellow.500'}
                  fontSize={{ base: '2xl', sm: '4xl', lg: '5xl' }}
                >
                  Экструдированный Корм
                </Heading>
              </Box>

              <Stack
                spacing={{ base: 4, sm: 6 }}
                direction={'column'}
                divider={
                  <StackDivider
                    borderColor={useColorModeValue('gray.200', 'gray.600')}
                  />
                }
              >
                <VStack spacing={{ base: 4, sm: 6 }}>
                  <Text
                    color={useColorModeValue('gray.500', 'gray.400')}
                    fontWeight={'800'}
                    fontSize={'2xl'}
                  >
                    Производитель: ООО "Кормо-Стандарт"
                  </Text>

                  <Text
                    color={useColorModeValue('gray.500', 'gray.400')}
                    fontWeight={'600'}
                    fontSize={'xl'}
                  >
                    Упаковка: полипропиленовый мешок 20кг
                  </Text>
                  <Text
                    color={useColorModeValue('gray.500', 'gray.400')}
                    fontWeight={'600'}
                    fontSize={'xl'}
                  >
                    Форм-фактор: вспученная форма
                  </Text>

                  <Text
                    fontSize={'lg'}
                    color={useColorModeValue('gray.500', 'gray.400')}
                    fontWeight={'600'}
                  >
                    Состав: пшеница,ячмень, овес, горох, кукуруза
                  </Text>
                  <Text fontSize={'lg'}>
                    Условия хранения: чистое, сухое, хорошо проветриваемое
                    помещение, не зараженное вредителями хлебных запасов
                  </Text>

                  <Text fontSize={'lg'}>
                    ВЛИЯНИЕ: Применение экструдированной кормовой смеси в
                    откорме КРС, свиней, птицы, кроликов, пушных зверей
                    показывает хорошие результаты. Так, надои молока могут
                    увеличиваться на 20-40%, прирост живого веса молодых
                    животных на 30-40%, яйценоскость птицы на 20-25%.
                    Экструдированная кормовая смесь незаменима при откорме
                    молодняка животных. Известно, что 90% падежа молодняка
                    происходит из-за болезней желудочно-кишечного тракта или
                    инфекций, занесенных с кормом. Данный корм имеет высокий
                    санитарный статус, он практически стерилен и может храниться
                    3-4 месяца в обычных складских условиях. Экструдированный
                    корм имеет уникальную структуру, питательную ценность и
                    усваиваемость, при которых организм животного тратит минимум
                    энергии на переваривание. Максимум энергии уходит в рост
                    мышечной массы.
                  </Text>
                  <Button
                    color={'yellow.500'}
                    m={'3'}
                    fontSize={'35'}
                    href={'/pricepage'}
                    cursor={'pointer'}
                    as={'a'}
                  >
                    Узнать цену
                  </Button>
                </VStack>
              </Stack>
            </Stack>
          </SimpleGrid>
        </Container>
      </Container>
      <Footer />
    </ChakraProvider>
  );
};

export default ExtrudedKormPage;
