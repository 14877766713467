import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import theme from '../../theme';
import { SimpleGrid, Container, Button } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';

import Header from '../../components/Header';
import Card from '../../components/Card.js';
import imageOne from '../../assets/img/branPush.jpg';
import imageTwo from '../../assets/img/otrubi.jpg';

import Footer from '../../components/Footer';
import DividerHeader from '../../components/DividerHeader';
import ScrollToTop from '../../components/ScrollToTop';

const BranPage = () => {
  return (
    <ChakraProvider theme={theme}>
      <Header />
      <Button m={'3'} href={'/'} cursor={'pointer'} as={'a'}>
        <ArrowBackIcon boxSize={10} color={'yellow.500'} />
      </Button>
      <Container maxW={'full'} h={'180vh'} centerContent>
        <DividerHeader name="Отруби" id="contacts" />
        <SimpleGrid columns={[1, 2, 2, 2]} spacing="40px">
          <Card
            title="Пушистые"
            subtitle="отруби"
            image={imageOne}
            href={'/branPush'}
          />
          <Card
            title="Гранулированные"
            subtitle="отруби"
            image={imageTwo}
            href={'/branGran'}
          />
        </SimpleGrid>
      </Container>
      <ScrollToTop />
      <Footer />
    </ChakraProvider>
  );
};

export default BranPage;
