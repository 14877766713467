import React from 'react';

var items = {
  item1: {
    title: 'Отруби пушистые',
    weight: '25 кг',
    price: '175 ₽',
  },
  item2: {
    title: 'Отруби пушистые (1 тонна)',
    weight: '1000 кг',
    price: '7000 ₽',
  },
  item3: {
    title: 'Отруби гранулированные',
    weight: '40 кг',
    price: '360 ₽',
  },
  item4: {
    title: 'Пшеница',
    weight: '35 кг',
    price: '500 ₽',
  },
  item5: {
    title: 'Ячмень',
    weight: '35 кг',
    price: '420 ₽',
  },
  item6: {
    title: 'ПК Курица несушка',
    weight: '35 кг',
    price: '550 ₽',
  },
  item7: {
    title: 'ПК-6 для подрощенных цыплят бройлеров',
    weight: '10/35 кг',
    price: '900 ₽',
  },
  item8: {
    title: 'ПК-5 для цыплят бройлеров',
    weight: '10/35 кг',
    price: '1000 ₽',
  },
  item9: {
    title: 'ПК-2 для цыплят',
    weight: '10/35 кг',
    price: '950 ₽',
  },
  item10: {
    title: 'Кормосмесь для свиней гранула 6мм',
    weight: '35 кг',
    price: '400 ₽',
  },
  item11: {
    title: 'Кормосмесь для КРС гранула 6мм',
    weight: '35 кг',
    price: '400 ₽',
  },
  item12: {
    title: 'Кукуруза',
    weight: '40 кг',
    price: '1000 ₽',
  },
  item13: {
    title: 'Овес',
    weight: '30 кг',
    price: '330 ₽',
  },
  item14: {
    title: 'Горох',
    weight: '35 кг',
    price: '600 ₽',
  },
  item15: {
    title: 'БМВК для дойных коров',
    weight: '40 кг',
    price: '1200 ₽',
  },
  item16: {
    title: 'Зерноотходы льняные',
    weight: '35 кг',
    price: '200 ₽',
  },
  item17: {
    title: 'Комбикорм для собак',
    weight: '10 кг',
    price: '200 ₽',
  },
  item18: {
    title: 'Комбикорм для собак',
    weight: '35 кг',
    price: '700 ₽',
  },

  item19: {
    title: 'Мучка гороховая',
    weight: '35 кг',
    price: 'нет в наличии',
  },
  item20: {
    title: 'КК-60 для дойных коров в стойловый период гранула 6мм ',
    weight: '35 кг',
    price: '670 ₽',
  },
  item21: {
    title: 'СК-4 для поросят гранула 4мм',
    weight: '35 кг',
    price: '900 ₽',
  },
  item22: {
    title: 'КК Универсальный для сельхоз-птицы гранула 4мм',
    weight: '35 кг',
    price: '400 ₽',
  },
  item23: {
    title: 'КК ПЗК-90 для кроликов',
    weight: '35 кг',
    price: '550 ₽',
  },
  item24: {
    title: 'КК Универсальный для свиней гранула 6мм',
    weight: '35 кг',
    price: '450 ₽',
  },
  item25: {
    title: 'КК Универсальный для КРС гранула 6мм',
    weight: '35 кг',
    price: '450 ₽',
  },
  item26: {
    title: 'Жмых подсолнечный',
    weight: '40 кг',
    price: '700 ₽',
  },
  item27: {
    title: 'Гранула травянная',
    weight: '40 кг',
    price: '950 ₽',
  },
  item28: {
    title: 'Мука рыбная',
    weight: '5/30 кг',
    price: '90 ₽/кг',
  },
  item29: {
    title: 'Мука мясо-костная',
    weight: '5/40 кг',
    price: '80 ₽/кг',
  },
  item30: {
    title: 'Ракушка дробленная',
    weight: '10 кг',
    price: '120 ₽',
  },
  item31: {
    title: 'Экструдированный комбикорм',
    weight: '20 кг',
    price: 'нет в наличии',
  },
  item32: {
    title: 'Дробленка(пшеница,ячмень,горох,кукуруза) помол сито 6мм',
    weight: '35 кг',
    price: '550 ₽',
  },
  item33: {
    title: 'Зерноотходы пшеничные',
    weight: '35 кг',
    price: '300 ₽',
  },
};

export default items;
