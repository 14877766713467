import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import theme from '../../theme';

import { ArrowBackIcon } from '@chakra-ui/icons';

import Header from '../../components/Header';

import {
  Box,
  Container,
  Stack,
  Text,
  Image,
  Flex,
  VStack,
  Button,
  Heading,
  SimpleGrid,
  StackDivider,
  useColorModeValue,
} from '@chakra-ui/react';

import Footer from '../../components/Footer';

import image from '../../assets/img/kombikormPicture.jpg';

const SK4Page = () => {
  return (
    <ChakraProvider theme={theme}>
      <Header />
      <Button m={'3'} href={'/compoundforPig'} cursor={'pointer'} as={'a'}>
        <ArrowBackIcon boxSize={10} color={'yellow.500'} />
      </Button>
      <Container maxW={'full'} h={'280vh'} centerContent>
        <Container maxW={'7xl'}>
          <SimpleGrid
            columns={{ base: 1, lg: 2 }}
            spacing={{ base: 8, md: 10 }}
            py={{ base: 18, md: 24 }}
          >
            <Flex>
              <Image
                rounded={'md'}
                alt={'product image'}
                src={image}
                fit={'cover'}
                align={'center'}
                w={'100%'}
                h={{ base: '100%', sm: '400px', lg: '500px' }}
              />
            </Flex>
            <Stack spacing={{ base: 6, md: 10 }}>
              <Box as={'header'}>
                <Heading
                  lineHeight={1.1}
                  fontWeight={600}
                  color={'yellow.500'}
                  fontSize={{ base: '2xl', sm: '4xl', lg: '5xl' }}
                >
                  СК-4 Для поросят
                </Heading>
              </Box>

              <Stack
                spacing={{ base: 4, sm: 6 }}
                direction={'column'}
                divider={
                  <StackDivider
                    borderColor={useColorModeValue('gray.200', 'gray.600')}
                  />
                }
              >
                <VStack spacing={{ base: 4, sm: 6 }}>
                  <Text
                    color={useColorModeValue('gray.500', 'gray.400')}
                    fontWeight={'800'}
                    fontSize={'2xl'}
                  >
                    Производитель: ООО "Кормо-Стандарт"
                  </Text>

                  <Text
                    color={useColorModeValue('gray.500', 'gray.400')}
                    fontWeight={'600'}
                    fontSize={'xl'}
                  >
                    Упаковка: полипропиленовый мешок 35кг
                  </Text>
                  <Text
                    color={useColorModeValue('gray.500', 'gray.400')}
                    fontWeight={'600'}
                    fontSize={'xl'}
                  >
                    Форм-фактор: гранула 4мм
                  </Text>
                  <Text
                    color={useColorModeValue('gray.500', 'gray.400')}
                    fontWeight={'600'}
                    fontSize={'xl'}
                  >
                    ГОСТ Р 51550-2000
                  </Text>

                  <Text fontSize={'lg'}>
                    Условия хранения: чистое, сухое, хорошо проветриваемое
                    помещение, не зараженное вредителями хлебных запасов
                  </Text>
                  <Text fontSize={'lg'}>
                    Состав: пшеница, ячмень, кукуруза, горох,жмых подсолнечный,
                    глютен кукурузный, шрот соевый, масло подсолнечное, мука
                    мясо-костная,мука рыбная, дрожжи кормовые, лизин, метионин,
                    соль поваренная, натрия сульфат, монокальцийфосфат,
                    известняковая мука, премикс
                  </Text>
                  <Text fontSize={'lg'}>
                    Показатели качества: Сырой протеин 18 % Сырая клетчатка 3,6
                    % Сырой жир 3,33 % Лизин 1 % Метионин+цистин 0,54% Кальций
                    1,1% Фосфор 0,9 % Обменная энергия 12,2 МДж/кг
                  </Text>
                  <Text fontSize={'lg'}>
                    Указания по применению: Рекомендации по применению: 43–45
                    дней -.600 г на голову/сутки 46–50 дней - 700 г на
                    голову/сутки 51–55 дней - 800 г на голову/сутки 56–60 дней -
                    900 г на голову/сутки
                  </Text>
                  <Button
                    color={'yellow.500'}
                    m={'3'}
                    fontSize={'35'}
                    href={'/pricepage'}
                    cursor={'pointer'}
                    as={'a'}
                  >
                    Узнать цену
                  </Button>
                </VStack>
              </Stack>
            </Stack>
          </SimpleGrid>
        </Container>
      </Container>
      <Footer />
    </ChakraProvider>
  );
};

export default SK4Page;
