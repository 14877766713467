import React from 'react';
import { SimpleGrid, Container } from '@chakra-ui/react';
import Card from './Card.js';
import imageOne from '../assets/img/zerno-korm.jpg';
import imageTwo from '../assets/img/kombi.jpg';
import imageThree from '../assets/img/otrubi.jpg';
import imageFour from '../assets/img/extrud.jpg';
import imageFive from '../assets/img/BMVK.jpeg';
const Products = () => {
  return (
    <Container maxW={'full'} centerContent>
      <SimpleGrid columns={[1, 1, 2, 2, 3, 5]} spacing="40px">
        <Card
          title="Зерновые"
          subtitle="корма"
          image={imageOne}
          href={'/cereals'}
        />
        <Card
          title="Комбикорма"
          subtitle="корма"
          image={imageTwo}
          href={'/compoundFeed'}
        />
        <Card
          title="Отруби"
          subtitle="корма"
          image={imageThree}
          href={'/bran'}
        />
        <Card
          title="Экструдированные"
          subtitle="корма"
          image={imageFour}
          href={'/extruded'}
        />
        <Card
          title="Премиксы и БВМК"
          subtitle="корма"
          image={imageFive}
          href={'/bmvk'}
        />
      </SimpleGrid>
    </Container>
  );
};
export default Products;
