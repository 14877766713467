import React from 'react';
import { ChakraProvider, Button } from '@chakra-ui/react';

import theme from '../../theme';

import Header from '../../components/Header';
import TableDash from '../../components/TableDash';
import Footer from '../../components/Footer';
import DividerHeader from '../../components/DividerHeader';
import PrintComponent from '../../components/PrintButton';

import { ArrowBackIcon } from '@chakra-ui/icons';

const PricePage = () => {
  return (
    <ChakraProvider theme={theme}>
      <Header />
      <Button m={'3'} href={'/'} cursor={'pointer'} as={'a'}>
        <ArrowBackIcon boxSize={10} color={'yellow.500'} />
      </Button>

      <DividerHeader name="Цены :" id="price" />
      <TableDash />

      <PrintComponent />
      <Footer />
    </ChakraProvider>
  );
};

export default PricePage;
