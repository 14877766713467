import React from 'react';
import {
  Box,
  Center,
  useColorModeValue,
  Heading,
  Text,
  Stack,
  Image,
  chakra,
  Button,
} from '@chakra-ui/react';

export default function CardProduct(props) {
  const IMAGE = props.image;

  return (
    <chakra.button href={props.href} cursor={'pointer'} as={'a'}>
      <Center py={12}>
        <Box
          role={'group'}
          p={6}
          maxW={'350px'}
          height={'450px'}
          w={'full'}
          bg={useColorModeValue('white', 'gray.800')}
          boxShadow={'2xl'}
          rounded={'lg'}
          pos={'relative'}
          zIndex={1}
        >
          <Box
            rounded={'lg'}
            mt={-12}
            pos={'relative'}
            height={'230px'}
            _after={{
              transition: 'all .3s ease',
              content: '""',
              w: 'full',
              h: 'full',
              pos: 'absolute',
              top: 5,
              left: 0,
              backgroundImage: `url(${IMAGE})`,
              filter: 'blur(15px)',
              zIndex: -1,
            }}
            _groupHover={{
              _after: {
                filter: 'blur(20px)',
              },
            }}
          >
            <Image
              margin={'auto'}
              rounded={'lg'}
              height={230}
              width={282}
              objectFit={'cover'}
              src={IMAGE}
            />
          </Box>
          <Stack pt={10} align={'center'}>
            <Stack direction={'row'} align={'center'} spacing={10}>
              <Heading
                color={'yellow.500'}
                fontSize={'3xl'}
                fontFamily={'body'}
                fontWeight={500}
              >
                {props.title}
              </Heading>
              <Heading
                color={'gray.500'}
                fontSize={'xl'}
                fontFamily={'body'}
                fontWeight={500}
                as="i"
              >
                {props.optionTitle}
              </Heading>
            </Stack>

            <Stack direction={'row'} align={'left'} spacing={20}>
              <Text color={'gray.600'} fontSize={'xl'} as="cite">
                {props.subtitle}
              </Text>
              <Text fontWeight={900} fontSize={'2xl'} color={'green.500'}>
                {' '}
                {props.price}
              </Text>
            </Stack>
            <Text color={'gray.600'} fontSize={'2sm'} noOfLines={2}>
              {props.optionaldescription}
            </Text>
            <Text color={'gray.600'} fontSize={'2sm'} as="i">
              {props.description}
            </Text>
          </Stack>
        </Box>
      </Center>
    </chakra.button>
  );
}
