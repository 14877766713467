import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import theme from '../../theme';

import { ArrowBackIcon } from '@chakra-ui/icons';

import Header from '../../components/Header';

import {
  Box,
  Container,
  Stack,
  Text,
  Image,
  Flex,
  VStack,
  Button,
  Heading,
  SimpleGrid,
  StackDivider,
  useColorModeValue,
} from '@chakra-ui/react';

import Footer from '../../components/Footer';
import image from '../../assets/img/kombikormPicture.jpg';

const KKDogDesPage = () => {
  return (
    <ChakraProvider theme={theme}>
      <Header />
      <Button m={'3'} href={'/compoundforDog'} cursor={'pointer'} as={'a'}>
        <ArrowBackIcon boxSize={10} color={'yellow.500'} />
      </Button>
      <Container maxW={'full'} h={'280vh'} centerContent>
        <Container maxW={'7xl'}>
          <SimpleGrid
            columns={{ base: 1, lg: 2 }}
            spacing={{ base: 8, md: 10 }}
            py={{ base: 18, md: 24 }}
          >
            <Flex>
              <Image
                rounded={'md'}
                alt={'product image'}
                src={image}
                fit={'cover'}
                align={'center'}
                w={'100%'}
                h={{ base: '100%', sm: '400px', lg: '500px' }}
              />
            </Flex>
            <Stack spacing={{ base: 6, md: 10 }}>
              <Box as={'header'}>
                <Heading
                  lineHeight={1.1}
                  fontWeight={600}
                  color={'yellow.500'}
                  fontSize={{ base: '2xl', sm: '4xl', lg: '5xl' }}
                >
                  Полнорационный комбикорм для собак
                </Heading>
              </Box>

              <Stack
                spacing={{ base: 4, sm: 6 }}
                direction={'column'}
                divider={
                  <StackDivider
                    borderColor={useColorModeValue('gray.200', 'gray.600')}
                  />
                }
              >
                <VStack spacing={{ base: 4, sm: 6 }}>
                  <Text
                    color={useColorModeValue('gray.500', 'gray.400')}
                    fontWeight={'800'}
                    fontSize={'2xl'}
                  >
                    Производитель: ООО "Кормо-Стандарт"
                  </Text>

                  <Text
                    color={useColorModeValue('gray.500', 'gray.400')}
                    fontWeight={'600'}
                    fontSize={'xl'}
                  >
                    Упаковка: полипропиленовый мешок 35кг/10кг
                  </Text>
                  <Text
                    color={useColorModeValue('gray.500', 'gray.400')}
                    fontWeight={'600'}
                    fontSize={'xl'}
                  >
                    Форм-фактор: гранула 4мм
                  </Text>
                  <Text
                    color={useColorModeValue('gray.500', 'gray.400')}
                    fontWeight={'600'}
                    fontSize={'xl'}
                  >
                    Стандарт: ГОСТ Р 51899-2002
                  </Text>

                  <Text fontSize={'lg'}>
                    Условия хранения: чистое, сухое, хорошо проветриваемое
                    помещение, не зараженное вредителями хлебных запасов. Срок
                    хранения - 3 месяца со дня изготовления при соблюдении
                    условий хранения.
                  </Text>
                  <Text fontSize={'lg'}>
                    Состав: Пшеница,горох,геркулес,масло подсолнечное,дрожжи
                    кормовые,мука мясокостная,мука рыбная,мел
                    кормовой,лизин,метионин,соя полножирная,соль
                    поваренная,монокальций фосфат,глютен
                    кукурузный,антиоксидант.
                  </Text>
                  <Text fontSize={'lg'}>
                    Показатели качества: Влажность - 14%,клетчатка-3.4% ,сырой
                    протеин - 18.5% , кальций - 1.1% , фосфор -0.8% , лизин -
                    0.70% , метионин - 0.5% , Метионин+цистин -0.5%, натрий
                    хлористый - 0.2%; Обменная энергия в 1кг Ккал/кг.-259,1
                  </Text>
                  <Text fontSize={'lg'}>
                    Указания по применению: скармливать как полннорационный
                    комбикорм
                  </Text>
                  <Button
                    color={'yellow.500'}
                    m={'3'}
                    fontSize={'35'}
                    href={'/pricepage'}
                    cursor={'pointer'}
                    as={'a'}
                  >
                    Узнать цену
                  </Button>
                </VStack>
              </Stack>
            </Stack>
          </SimpleGrid>
        </Container>
      </Container>
      <Footer />
    </ChakraProvider>
  );
};

export default KKDogDesPage;
