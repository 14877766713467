import React from 'react';
import { ChakraProvider, Button,Center } from '@chakra-ui/react';

import theme from '../../theme';

import Header from '../../components/Header';
import ProductDash from '../../components/ProductDash';
import Footer from '../../components/Footer';
import DividerHeader from '../../components/DividerHeader';
import PrintComponent from '../../components/PrintButton';

import { ArrowBackIcon } from '@chakra-ui/icons';

const AdminPage = () => {
  return (
    <ChakraProvider theme={theme}>
      <Header />
      <Button m={'3'} href={'/'} cursor={'pointer'} as={'a'}>
        <ArrowBackIcon boxSize={10} color={'yellow.500'} />
      </Button>
      <Center mb={'125'}>
        <DividerHeader
          name="Панель администратора kormostandart.ru :"
          id="price"
        />
      </Center>

      <ProductDash />

      <Footer />
    </ChakraProvider>
  );
};

export default AdminPage;
