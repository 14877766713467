import React, { useState, useEffect } from 'react';
import { ChakraProvider, Button } from '@chakra-ui/react';
import theme from '../../../../theme';
import { SimpleGrid, Container } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';

import Header from '../../../../components/Header';

import imageOne from '../../../../assets/img/cow1.jpg';
import imageTwo from '../../../../assets/img/cow3.jpg';

import CardProduct from '../../../../components/CardProduct.js';
import Footer from '../../../../components/Footer';
import DividerHeader from '../../../../components/DividerHeader';


import Loading from '../../../../components/Loading';
import axios from 'axios';
import serverURL from '../../../../services/serverURL.jsx';

const CowPage = () => {
  const [forcow, setForcow] = useState<any>(null);

  // Функция для выполнения GET-запроса к серверу и получения данных о товарах
  const fetchForcow = async () => {
    try {
      const response = await axios.get(`${serverURL}/forcow`);
      setForcow(response.data);
    } catch (error) {
      console.error('Ошибка при получении данных:', error);
    }
  };

  useEffect(() => {
    fetchForcow();
  }, []);

  if (!forcow) {
    return <Loading></Loading>;
  }
  return (
    <ChakraProvider theme={theme}>
      <Header />
      <Button m={'3'} href={'/compoundFeed'} cursor={'pointer'} as={'a'}>
        <ArrowBackIcon boxSize={10} color={'yellow.500'} />
      </Button>
      <Container maxW={'full'} h={'190vh'} centerContent>
        <DividerHeader name="Для коров и телят" id="contacts" />

        <SimpleGrid columns={[1, 1, 1, 2, 2, 2]} spacing="40px">
          <CardProduct
            title="Комбикорм"
            optionTitle="Для КРС"
            subtitle={`Мешки ${forcow[0].weight}`}
            description="Подробнее..."
            price={`${forcow[0].price}`}
            image={imageOne}
            href={'/cowkorm'}
          />
          <CardProduct
            title="Комбикорм КК-60"
            optionTitle="для дойных коров в стойловый период"
            subtitle={`Мешки ${forcow[1].weight}`}
            description="Подробнее..."
            price={`${forcow[1].price}`}
            image={imageTwo}
            href={'/kk60'}
          />
        </SimpleGrid>
      </Container>
      <Footer />
    </ChakraProvider>
  );
};

export default CowPage;
