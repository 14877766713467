import React, { useState, useEffect } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableContainer,
  Container,
  SimpleGrid,
  Button,
} from '@chakra-ui/react';
import axios from 'axios';
import serverURL from '../services/serverURL.jsx';
import Loading from '../components/Loading.jsx';

function ProductDash() {
  const [items, setItems] = useState<any[]>([]);

  // Функция для выполнения GET-запроса к серверу и получения данных о товарах
  const fetchItems = async () => {
    try {
      const response = await axios.get(serverURL);
      setItems(response.data);
    } catch (error) {
      console.error('Ошибка при получении данных:', error);
    }
  };

  useEffect(() => {
    fetchItems();
  }, []);

  if (!items) {
    return <Loading></Loading>;
  }
  const myitems = Object.values(items);
  const tableItems1 = myitems.slice(0, myitems.length / 2);
  const tableItems2 = myitems.slice(myitems.length / 2, myitems.length);

  return (
    <div>
      <SimpleGrid columns={[1, 1, 1, 1, 1, 2]} spacing="5px">
        <Container maxW="900px" marginBottom={5} bg="" color="gray.800">
          <TableContainer>
            <Table
              variant="striped"
              colorScheme="gray"
              size="md"
              color="gray.650"
              fontSize={'xl'}
            >
              <Thead>
                <Tr>
                  <Th>наименова́ние</Th>
                  <Th>Вес</Th>
                  <Th isNumeric>Цена</Th>
                </Tr>
              </Thead>

              <Tbody>
                {tableItems1.map((tableItem, index) => (
                  <Tr>
                    <Td>{tableItem.title}</Td>
                    <Td isNumeric>{tableItem.weight}</Td>
                    <Td isNumeric>{tableItem.price}</Td>
                  </Tr>
                ))}
              </Tbody>
              <Tfoot></Tfoot>
            </Table>
          </TableContainer>
        </Container>

        <Container maxW="900px" bg="" color="gray.800">
          <TableContainer>
            <Table
              variant="striped"
              colorScheme="gray"
              size="md"
              color="gray.650"
              fontSize={'xl'}
            >
              <Thead>
                <Tr>
                  <Th>наименова́ние</Th>
                  <Th>Вес</Th>
                  <Th isNumeric>Цена</Th>
                </Tr>
              </Thead>
              <Tbody>
                {tableItems2.map((tableItem, index) => (
                  <Tr>
                    <Td>{tableItem.title}</Td>
                    <Td isNumeric>{tableItem.weight}</Td>
                    <Td isNumeric>{tableItem.price}</Td>
                  </Tr>
                ))}
              </Tbody>
              <Tfoot></Tfoot>
            </Table>
          </TableContainer>
        </Container>
      </SimpleGrid>
    </div>
  );
}

export default ProductDash;
