import React from 'react';
import {
  Stack,
  chakra,
  useColorModeValue,
  VisuallyHidden,
  Flex,
  Button,
  Text,
  VStack,
  useBreakpointValue,
  Tooltip,
} from '@chakra-ui/react';
import bgImage from '../assets/img/full.jpg';
import { ReactNode } from 'react';
import { PhoneIcon } from '@chakra-ui/icons';
import { FaTelegram, FaWhatsapp } from 'react-icons/fa';

const SocialButton = ({
  children,
  label,
  href,
}: {
  children: ReactNode;
  label: string;
  href: string;
}) => {
  return (
    <chakra.button
      bg={useColorModeValue('green.300', 'whiteAlpha.100')}
      rounded={'full'}
      w={'65px'}
      h={10}
      margin={2}
      cursor={'pointer'}
      as={'a'}
      href={'https://api.whatsapp.com/send/?phone=79659709820'}
      display={'inline-flex'}
      alignItems={'center'}
      justifyContent={'center'}
      transition={'background 0.3s ease'}
      _hover={{
        bg: useColorModeValue('green.200', 'whiteAlpha.200'),
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

export default function About() {
  return (
    <Flex
      w={'full'}
      h={'50vh'}
      backgroundImage={bgImage}
      backgroundSize={'cover'}
      backgroundPosition={'center center'}
    >
      <VStack
        w={'full'}
        justify={'center'}
        px={useBreakpointValue({ base: 4, md: 8 })}
        bgGradient={'linear(to-r, blackAlpha.600, transparent)'}
      >
        <Stack maxW={'3xl'} align={'flex-center'} spacing={6}>
          <Text
            color={'white'}
            fontWeight={700}
            lineHeight={1.2}
            fontSize={useBreakpointValue({ base: 'xl', md: '4xl' })}
          >
            ООО "Кормо-Стандарт", производим и продаем высококачественные
            комбикорма для всех видов с/х животных
          </Text>

          <Stack direction={['column', 'row']}>
            <Button
              bg={'green.400'}
              rounded={'full'}
              color={'white'}
              pl={'30px'}
              pr={'30px'}
              _hover={{ bg: 'green.500' }}
            >
              <Tooltip label="Phone number" fontSize="md">
                <PhoneIcon />
              </Tooltip>
              <a href="tel:+79659709820"> +7 965 970 98 20 </a>
            </Button>
            <Button
              bg={'green.400'}
              rounded={'full'}
              color={'white'}
              pl={'30px'}
              pr={'30px'}
              _hover={{ bg: 'green.500' }}
            >
              <Tooltip label="Phone number" fontSize="md">
                <PhoneIcon />
              </Tooltip>
              <a href="tel:+79835257506"> +7 983 525 75 06 </a>
            </Button>
            <Button
              bg={'yellow.500'}
              rounded={'full'}
              color={'white'}
              pl={'30px'}
              pr={'30px'}
              _hover={{ bg: 'yellow.400' }}
            >
              <a href="mailto: kormostandart@mail.ru">kormostandart@mail.ru</a>
            </Button>
            <Button
              bg={'yellow.500'}
              rounded={'full'}
              color={'white'}
              _hover={{ bg: 'yellow.400' }}
            >
              <Text color={'white'} lineHeight={1.2}>
                <a href="https://api.whatsapp.com/send/?phone=79659709820">
                  {' '}
                  WhatsApp
                </a>
              </Text>

              <SocialButton label={'Whatsapp'} href={'#'}>
                <FaWhatsapp />
              </SocialButton>
            </Button>
          </Stack>
        </Stack>
      </VStack>
    </Flex>
  );
}
