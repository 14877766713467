import React, { useState, useEffect } from 'react';
import { ChakraProvider, Button } from '@chakra-ui/react';
import theme from '../../../../theme';
import { SimpleGrid, Container } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';

import Header from '../../../../components/Header';

import imageOne from '../../../../assets/img/pig1.jpg';
import imageTwo from '../../../../assets/img/pig2.jpg';
import imageThree from '../../../../assets/img/porosenok.jpg';

import CardProduct from '../../../../components/CardProduct.js';
import Footer from '../../../../components/Footer';
import DividerHeader from '../../../../components/DividerHeader';


import Loading from '../../../../components/Loading';
import axios from 'axios';
import serverURL from '../../../../services/serverURL.jsx';

const PigPage = () => {
   const [forpig, setForpig] = useState<any>(null);

   // Функция для выполнения GET-запроса к серверу и получения данных о товарах
   const fetchForpig = async () => {
     try {
       const response = await axios.get(`${serverURL}/forpig`);
       setForpig(response.data);
     } catch (error) {
       console.error('Ошибка при получении данных:', error);
     }
   };

   useEffect(() => {
     fetchForpig();
   }, []);

   if (!forpig) {
     return <Loading></Loading>;
   }
  return (
    <ChakraProvider theme={theme}>
      <Header />
      <Button m={'3'} href={'/compoundFeed'} cursor={'pointer'} as={'a'}>
        <ArrowBackIcon boxSize={10} color={'yellow.500'} />
      </Button>
      <Container maxW={'full'} h={'280vh'} centerContent>
        <DividerHeader name="Для свиней и поросят" id="contacts" />

        <SimpleGrid columns={[1, 1, 2, 2, 3, 3]} spacing="40px">
          <CardProduct
            title="Кормосмесь"
            optionTitle=""
            subtitle={`Мешки ${forpig[0].weight}`}
            description="Подробнее..."
            price={`${forpig[0].price}`}
            image={imageOne}
            href={'/kormosmes'}
          />
          <CardProduct
            title="Универсальный"
            optionTitle="ПК-55"
            subtitle={`Мешки ${forpig[1].weight}`}
            description="Подробнее..."
            price={`${forpig[1].price}`}
            image={imageTwo}
            href={'piguniversal'}
          />
          <CardProduct
            title="СК-4"
            optionTitle="Для поросят"
            subtitle={`Мешки ${forpig[2].weight}`}
            description="Подробнее..."
            price={`${forpig[2].price}`}
            image={imageThree}
            href={'sk4'}
          />
        </SimpleGrid>
      </Container>
      <Footer />
    </ChakraProvider>
  );
};

export default PigPage;
