import {
  Box,
  Container,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Center,
} from '@chakra-ui/react';
import React from 'react';

const FaqSection = () => {
  return (
    <Center>
      <Container maxW="1500px" m={10} bg="gray.50" color="gray.800">
        <Accordion>
          <AccordionItem>
            <h2>
              <AccordionButton _expanded={{ bg: 'gray.200', color: 'white' }}>
                <Box
                  flex="1"
                  textAlign="center"
                  fontSize={'xl'}
                  color={'yellow.500'}
                >
                  Можно ли купить 1 мешок?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel color={'yellow.500'}>
              <Box
                flex="1"
                textAlign="center"
                fontSize={'xl'}
                color={'green.300'}
              >
                Да, у нас вы можете приобрести 1 мешок любой продукции.
              </Box>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton _expanded={{ bg: 'gray.200', color: 'white' }}>
                <Box
                  flex="1"
                  textAlign="center"
                  fontSize={'xl'}
                  color={'yellow.500'}
                >
                  Где вы находитесь ?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel>
              <Box
                flex="1"
                textAlign="center"
                fontSize={'xl'}
                color={'green.300'}
              >
                Мы находимся по адресу ул. Перова 43 корпус 9. Старый Кировск
                территория бывшего кожзавода. На проходной сказать что в
                комбикормовый цех.
              </Box>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton _expanded={{ bg: 'gray.200', color: 'white' }}>
                <Box
                  flex="1"
                  textAlign="center"
                  fontSize={'xl'}
                  color={'yellow.500'}
                >
                  С НДС или без НДС?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel>
              <Box
                flex="1"
                textAlign="center"
                fontSize={'xl'}
                color={'red.300'}
              >
                Без НДС.
              </Box>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton _expanded={{ bg: 'gray.200', color: 'white' }}>
                <Box
                  flex="1"
                  textAlign="center"
                  fontSize={'xl'}
                  color={'yellow.500'}
                >
                  Есть ли у вас доставка?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel>
              <Box
                flex="1"
                textAlign="center"
                fontSize={'xl'}
                color={'red.300'}
              >
                К сожалению доставки по городу и в область нет.
              </Box>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton _expanded={{ bg: 'gray.200', color: 'white' }}>
                <Box
                  flex="1"
                  textAlign="center"
                  fontSize={'xl'}
                  color={'yellow.500'}
                >
                  Есть ли погрузка ?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel>
              <Box
                flex="1"
                textAlign="center"
                fontSize={'xl'}
                color={'green.300'}
              >
                Уважаемые покупатели при покупке любой нашей продукции погрузка
                осуществляется нашими грузчиками в ваше транспортное средство.
                БЕСПЛАТНО.
              </Box>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton _expanded={{ bg: 'gray.200', color: 'white' }}>
                <Box
                  flex="1"
                  textAlign="center"
                  fontSize={'xl'}
                  color={'yellow.500'}
                >
                  Где приобрести вашу продукцию ?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel>
              <Box
                flex="1"
                textAlign="center"
                fontSize={'xl'}
                color={'green.300'}
              >
                Вы можете приобрести нашу продукцию в торговой точке на производстве. <br/>
                По адресу : улица Перова 43 корпус 9 
              </Box>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Container>
    </Center>
  );
};
export default FaqSection;
