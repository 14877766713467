import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import theme from '../../theme';
import { SimpleGrid, Container, Button } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import Card from '../../components/Card.js';

import imageOne from '../../assets/img/bird.jpg';
import imageTwo from '../../assets/img/pig.jpg';
import imageThree from '../../assets/img/cow.jpg';
import imageFour from '../../assets/img/rabbit.jpg';
import imageFive from '../../assets/img/ferm.jpg';
import imageSix from '../../assets/img/dog.jpg';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import DividerHeader from '../../components/DividerHeader';
import ScrollToTop from '../../components/ScrollToTop';

const CompoundFeedPage = () => {
  return (
    <ChakraProvider theme={theme}>
      <Header />
      <Button m={'3'} href={'/'} cursor={'pointer'} as={'a'}>
        <ArrowBackIcon boxSize={10} color={'yellow.500'} />
      </Button>
      <Container maxW={'full'} h={'350vh'} centerContent>
        <DividerHeader name="Комбикорма" id="contacts" />
        <SimpleGrid columns={[1, 1, 2, 2, 3, 3]} spacing="40px">
          <Card
            title="Сельхоз-птица"
            subtitle="комбикорм"
            image={imageOne}
            href={'/compoundforBird'}
          />
          <Card
            title="Свиньи"
            subtitle="комбикорм"
            image={imageTwo}
            href={'/compoundforPig'}
          />
          <Card
            title="Корова"
            subtitle="комбикорм"
            image={imageThree}
            href={'/compoundforCow'}
          />
          <Card
            title="Кролик"
            subtitle="комбикорм"
            image={imageFour}
            href={'/compoundforRabbit'}
          />
          <Card
            title="Собаки"
            subtitle="комбикорм"
            image={imageSix}
            href={'/compoundforDog'}
          />
          <Card
            title="Универсальные"
            subtitle="комбикорм"
            image={imageFive}
            href={'/compoundUniversal'}
          />
        </SimpleGrid>
      </Container>
      <ScrollToTop />
      <Footer />
    </ChakraProvider>
  );
};

export default CompoundFeedPage;
