import React from 'react';
import {
  Box,
  chakra,
  Container,
  Link,
  SimpleGrid,
  Stack,
  Text,
  VisuallyHidden,
  Input,
  IconButton,
  useColorModeValue,
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import { FaTelegram, FaWhatsapp, FaYoutube } from 'react-icons/fa';
import { BiMailSend } from 'react-icons/bi';

const SocialButton = ({
  children,
  label,
  href,
}: {
  children: ReactNode;
  label: string;
  href: string;
}) => {
  return (
    <chakra.button
      bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
      rounded={'full'}
      w={8}
      h={8}
      cursor={'pointer'}
      as={'a'}
      href={href}
      display={'inline-flex'}
      alignItems={'center'}
      justifyContent={'center'}
      transition={'background 0.3s ease'}
      _hover={{
        bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

const ListHeader = ({ children }: { children: ReactNode }) => {
  return (
    <Text fontWeight={'500'} fontSize={'lg'} mb={2}>
      {children}
    </Text>
  );
};

export default function LargeWithNewsletter() {
  return (
    <footer>
      <Box
        bg={useColorModeValue('gray.50', 'gray.900')}
        color={useColorModeValue('gray.700', 'gray.200')}
        w={'100%'}
      >
        <Container as={Stack} maxW={'6xl'} py={10}>
          <SimpleGrid
            templateColumns={{ sm: '1fr 1fr', md: '2fr 1fr 1fr 2fr' }}
            spacing={8}
          >
            <Stack spacing={6}>
              <Box>
                <Text>Кормостандарт</Text>
              </Box>
              <Stack direction={'row'} spacing={6}>
                <SocialButton label={'Telegram'} href={'#'}>
                  <FaTelegram />
                </SocialButton>
                <SocialButton label={'YouTube'} href={'#'}>
                  <FaYoutube />
                </SocialButton>
                <SocialButton
                  label={'Whatsapp'}
                  href={'https://api.whatsapp.com/send/?phone=79659709820'}
                >
                  <FaWhatsapp />
                </SocialButton>
              </Stack>
            </Stack>
            <Stack align={'flex-start'}>
              <ListHeader>Компания</ListHeader>
              <Link href={'#'}>О нас</Link>
              <Link href={'#'}>Инфо</Link>
              <Link href={'#contacts'}>Контакты</Link>
              <Link href={'#price'}>Цены</Link>
              <Link href={'#faq'}>Вопросы</Link>
            </Stack>
            <Stack align={'flex-start'}>
              <ListHeader>Помощь</ListHeader>
              <Link href={'#'}>Позвонить</Link>
              <Link
                href={
                  'https://api.whatsapp.com/send/?phone=79659709820&text=Здравствуйте%2C+у+меня+есть+вопрос'
                }
              >
                Есть вопросы
              </Link>
            </Stack>
            <Stack align={'flex-start'}>
              <ListHeader>Написать:</ListHeader>
              <Stack direction={'row'}>
                <Input
                  placeholder={'Ваш адресс почты'}
                  bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
                  border={0}
                  _focus={{
                    bg: 'whiteAlpha.300',
                  }}
                />
                <IconButton
                  bg={useColorModeValue('green.400', 'green.800')}
                  color={useColorModeValue('white', 'gray.800')}
                  _hover={{
                    bg: 'green.600',
                  }}
                  aria-label="Subscribe"
                  icon={<BiMailSend />}
                />
              </Stack>
            </Stack>
          </SimpleGrid>
        </Container>
      </Box>
    </footer>
  );
}
