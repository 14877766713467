import React from 'react';
import { ChakraProvider, Button } from '@chakra-ui/react';
import theme from '../../theme';
import { Container, Text } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';

import Header from '../../components/Header';

import Footer from '../../components/Footer';
import DividerHeader from '../../components/DividerHeader';

const UniversalPage = () => {
  return (
    <ChakraProvider theme={theme}>
      <Header />
      <Button m={'3'} href={'/compoundFeed'} cursor={'pointer'} as={'a'}>
        <ArrowBackIcon boxSize={10} color={'yellow.500'} />
      </Button>
      <Container maxW={'full'} h={'130vh'} centerContent>
        <DividerHeader name="Универсальные комбикорма" id="contacts" />

        <Text fontSize={'3xl'} color={'yellow.500'}>
          {' '}
          В разработке ...
        </Text>
      </Container>
      <Footer />
    </ChakraProvider>
  );
};

export default UniversalPage;
