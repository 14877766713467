import React, { useState, useEffect } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { SimpleGrid, Container, Button } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';

import theme from '../../theme';
import ScrollToTop from '../../components/ScrollToTop';

import Header from '../../components/Header';
import CardProduct from '../../components/CardProduct.js';
import imageOne from '../../assets/img/pshen.jpg';
import imageTwo from '../../assets/img/yachmen.jpg';
import imageThree from '../../assets/img/oves.jpg';
import imageFour from '../../assets/img/kukuruza.jpg';
import imageFive from '../../assets/img/zhmyh.png';
import imageSix from '../../assets/img/volga.jpg';
import imageSeven from '../../assets/img/muchkaGoroh.jpeg';
import imageEight from '../../assets/img/granulaTrav.jpg';
import imageNine from '../../assets/img/droblenka.jpg';
import imageTen from '../../assets/img/zernoothodi1.jpg';
import imageEleven from '../../assets/img/zernoothodi2.jpg';
import imageTwelve from '../../assets/img/lucern.jpeg';

import Footer from '../../components/Footer';
import DividerHeader from '../../components/DividerHeader';
import axios from 'axios';
import serverURL from '../../services/serverURL.jsx';
import Loading from '../../components/Loading';

const CerealsPage = () => {
  const [cereals, setCereals] = useState<any>(null);

  // Функция для выполнения GET-запроса к серверу и получения данных о товарах
  const fetchCereals = async () => {
    try {
      const response = await axios.get(`${serverURL}/cereals`);
      setCereals(response.data);
    } catch (error) {
      console.error('Ошибка при получении данных:', error);
    }
  };

  useEffect(() => {
    fetchCereals();
  }, []);

  if (!cereals) {
    return <Loading></Loading>;
  }
  return (
    <ChakraProvider theme={theme}>
      <Header />
      <Button m={'3'} href={'/'} cursor={'pointer'} as={'a'}>
        <ArrowBackIcon boxSize={10} color={'yellow.500'} />
      </Button>
      <DividerHeader name="Зерновые" id="contacts" />
      <Container maxW={'full'} h={'880vh'} centerContent>
        <SimpleGrid columns={[1, 1, 2, 2, 3, 4]} spacing="40px">
          <CardProduct
            title="Пшеница"
            subtitle={`Мешки ${cereals[0].weight}`}
            description="Оптовые цены по запросу"
            price={`${cereals[0].price}`}
            image={imageOne}
            href={'#'}
          />
          <CardProduct
            title="Ячмень"
            subtitle={`Мешки ${cereals[1].weight}`}
            description="Оптовые цены по запросу"
            price={`${cereals[1].price}`}
            image={imageTwo}
            href={'#'}
          />
          <CardProduct
            title="Овес"
            subtitle={`Мешки ${cereals[2].weight}`}
            description="Оптовые цены по запросу"
            price={`${cereals[2].price}`}
            image={imageThree}
            href={'#'}
          />
          <CardProduct
            title="Кукуруза"
            subtitle={`Мешки ${cereals[3].weight}`}
            description="Оптовые цены по запросу"
            price={`${cereals[3].price}`}
            image={imageFour}
            href={'#'}
          />
          <CardProduct
            title="Горох"
            subtitle={`Мешки ${cereals[4].weight}`}
            description="Оптовые цены по запросу"
            price={`${cereals[4].price}`}
            image={imageSix}
            href={'#'}
          />
          <CardProduct
            title="Жмых"
            optionTitle="Подсолнечный"
            subtitle={`Мешки ${cereals[5].weight}`}
            description="Оптовые цены по запросу"
            price={`${cereals[5].price}`}
            image={imageFive}
            href={'#'}
          />
          <CardProduct
            title="Мучка гороховая"
            subtitle={`Мешки ${cereals[6].weight}`}
            description="Оптовые цены по запросу"
            price={`${cereals[6].price}`}
            image={imageSeven}
            href={'#'}
          />
          <CardProduct
            title="Гранула травянная"
            subtitle={`Мешки ${cereals[7].weight}`}
            description="Оптовые цены по запросу"
            price={`${cereals[7].price}`}
            image={imageEight}
            href={'#'}
          />
          <CardProduct
            title="Зерноотходы пшеничные"
            subtitle={`Мешки ${cereals[8].weight}`}
            description="Оптовые цены по запросу"
            price={`${cereals[8].price}`}
            image={imageTen}
            href={'#'}
          />
          <CardProduct
            title="Зерноотходы льняные"
            subtitle={`Мешки ${cereals[9].weight}`}
            description="Оптовые цены по запросу"
            price={`${cereals[9].price}`}
            image={imageEleven}
            href={'#'}
          />
          <CardProduct
            title="Дробленка"
            subtitle={`Мешки ${cereals[10].weight}`}
            description="Оптовые цены по запросу"
            price={`${cereals[10].price}`}
            optionaldescription=" Состав: пшеница 40%, ячмень 40%,  горох 10%,кукуруза 10%"
            image={imageNine}
            href={'#'}
          />
          <CardProduct
            title="Люцерна"
            subtitle={`Мешки ${cereals[11].weight}`}
            description="Оптовые цены по запросу"
            price={`${cereals[11].price}`}
            image={imageTwelve}
            href={'#'}
          />
        </SimpleGrid>
      </Container>
      <ScrollToTop />
      <Footer />
    </ChakraProvider>
  );
};

export default CerealsPage;
