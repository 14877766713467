import React, { useState, useEffect } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableContainer,
  Container,
  SimpleGrid,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
} from '@chakra-ui/react';
import axios from 'axios';
import serverURL from '../services/serverURL.jsx';
import Loading from '../components/Loading.jsx';

function ProductDash() {
  const [items, setItems] = useState<any[]>([]);
  const [editItem, setEditItem] = useState<any>(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleEdit = (item: any) => {
    setEditItem(item);
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleSave = async () => {
    try {
      await axios.put(`${serverURL}/${editItem.title}`, editItem);
      console.log(`${serverURL}/${editItem.title}`);
      console.log(editItem);
      // Обновите данные на клиенте (items) с учетом изменений
      setItems(
        myitems.map(item => (item.title === editItem.title ? editItem : item))
      );
      setIsOpen(false);
    } catch (error) {
      console.error('Ошибка при обновлении данных:', error);
    }
  };

  // Функция для выполнения GET-запроса к серверу и получения данных о товарах
  const fetchItems = async () => {
    try {
      const response = await axios.get(serverURL);
      setItems(response.data);
    } catch (error) {
      console.error('Ошибка при получении данных:', error);
    }
  };

  useEffect(() => {
    fetchItems();
  }, []);

  const myitems = Object.values(items);
  const tableItems1 = myitems.slice(0, myitems.length / 2);
  const tableItems2 = myitems.slice(myitems.length / 2, myitems.length);

  if (!items) {
    return <Loading></Loading>;
  }

  return (
    <div>
      <SimpleGrid columns={[1, 1, 1, 1, 1, 2]} spacing="5px">
        <Container maxW="950px" marginBottom={5} bg="" color="gray.800">
          <TableContainer>
            <Table
              variant="striped"
              colorScheme="gray"
              size="md"
              color="gray.650"
              fontSize={'md'}
            >
              <Thead>
                <Tr>
                  <Th>наименова́ние</Th>
                  <Th>Вес</Th>
                  <Th isNumeric>Цена</Th>
                </Tr>
              </Thead>

              <Tbody>
                {tableItems1.map((tableItem, index) => (
                  <Tr>
                    <Td>{tableItem.title}</Td>
                    <Td isNumeric>{tableItem.weight}</Td>
                    <Td isNumeric>{tableItem.price}</Td>
                    <Button
                      colorScheme="yellow"
                      bgGradient="linear(to-r, yellow.400, yellow.500, yellow.600)"
                      color="white"
                      variant="solid"
                      m={5}
                      onClick={() => handleEdit(tableItem)}
                    >
                      Редактировать
                    </Button>
                  </Tr>
                ))}
              </Tbody>
              <Tfoot></Tfoot>
            </Table>
          </TableContainer>
        </Container>

        <Container maxW="950px" bg="" color="gray.800">
          <TableContainer>
            <Table
              variant="striped"
              colorScheme="gray"
              size="md"
              color="gray.650"
              fontSize={'md'}
            >
              <Thead>
                <Tr>
                  <Th>наименова́ние</Th>
                  <Th>Вес</Th>
                  <Th isNumeric>Цена</Th>
                </Tr>
              </Thead>
              <Tbody>
                {tableItems2.map((tableItem, index) => (
                  <Tr>
                    <Td>{tableItem.title}</Td>
                    <Td isNumeric>{tableItem.weight}</Td>
                    <Td isNumeric>{tableItem.price}</Td>
                    <Button
                      colorScheme="yellow"
                      bgGradient="linear(to-r, yellow.400, yellow.500, yellow.600)"
                      color="white"
                      variant="solid"
                      m={5}
                      onClick={() => handleEdit(tableItem)}
                    >
                      Редактировать
                    </Button>
                  </Tr>
                ))}
              </Tbody>
              <Tfoot></Tfoot>
            </Table>
          </TableContainer>
        </Container>
      </SimpleGrid>

      <Modal isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Редактировать товар</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Наименование</FormLabel>
              <Input value={editItem ? editItem.title : ''} />
            </FormControl>
            <FormControl>
              <FormLabel>Вес</FormLabel>
              <Input
                value={editItem ? editItem.weight : ''}
                onChange={e =>
                  setEditItem({ ...editItem, weight: e.target.value })
                }
              />
            </FormControl>
            <FormControl>
              <FormLabel>Цена</FormLabel>
              <Input
                value={editItem ? editItem.price : ''}
                onChange={e =>
                  setEditItem({ ...editItem, price: e.target.value })
                }
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              bgGradient="linear(to-r, yellow.400, yellow.500, yellow.600)"
              color="white"
              variant="solid"
              colorScheme="yellow"
              onClick={handleSave}
            >
              Сохранить
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default ProductDash;
