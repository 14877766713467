import React from 'react';
import { ChakraProvider, Text, Container } from '@chakra-ui/react';
import { Box, Heading, Button } from '@chakra-ui/react';
import theme from '../../theme';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

const NotFound = () => {
  return (
    <ChakraProvider theme={theme}>
      <Header />
      <Container maxW={'full'} h={'280vh'} centerContent>
        <Box textAlign="center" py={10} px={6}>
          <Heading
            display="inline-block"
            as="h2"
            size="2xl"
            bgGradient="linear(to-r, yellow.400, yellow.600)"
            backgroundClip="text"
          >
            404
          </Heading>
          <Text fontSize="18px" mt={3} mb={2}>
            Page Not Found
          </Text>
          <Text color={'gray.500'} mb={6}>
            Такой страницы не найденно
          </Text>

          <Button
            colorScheme="yellow"
            href={'/'}
            cursor={'pointer'}
            as={'a'}
            bgGradient="linear(to-r, yellow.400, yellow.500, yellow.600)"
            color="white"
            variant="solid"
          >
            Вернуться на главную
          </Button>
        </Box>
      </Container>
      <Footer />
    </ChakraProvider>
  );
};

export default NotFound;
