import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import theme from '../../theme';

import { ArrowBackIcon } from '@chakra-ui/icons';

import Header from '../../components/Header';

import {
  Box,
  Container,
  Stack,
  Text,
  Image,
  Flex,
  VStack,
  Button,
  Heading,
  SimpleGrid,
  StackDivider,
  useColorModeValue,
} from '@chakra-ui/react';

import Footer from '../../components/Footer';

import image from '../../assets/img/kombikormPicture.jpg';

const PK12Page = () => {
  return (
    <ChakraProvider theme={theme}>
      <Header />
      <Button m={'3'} href={'/compoundforBird'} cursor={'pointer'} as={'a'}>
        <ArrowBackIcon boxSize={10} color={'yellow.500'} />
      </Button>
      <Container maxW={'full'} h={'280vh'} centerContent>
        <Container maxW={'7xl'}>
          <SimpleGrid
            columns={{ base: 1, lg: 2 }}
            spacing={{ base: 8, md: 10 }}
            py={{ base: 18, md: 24 }}
          >
            <Flex>
              <Image
                rounded={'md'}
                alt={'product image'}
                src={image}
                fit={'cover'}
                align={'center'}
                w={'100%'}
                h={{ base: '100%', sm: '400px', lg: '500px' }}
              />
            </Flex>
            <Stack spacing={{ base: 6, md: 10 }}>
              <Box as={'header'}>
                <Heading
                  lineHeight={1.1}
                  fontWeight={600}
                  color={'yellow.500'}
                  fontSize={{ base: '2xl', sm: '4xl', lg: '5xl' }}
                >
                  ПК-1-2 Полнорационный комбикорм для взрослых кур-несушек
                </Heading>
              </Box>

              <Stack
                spacing={{ base: 4, sm: 6 }}
                direction={'column'}
                divider={
                  <StackDivider
                    borderColor={useColorModeValue('gray.200', 'gray.600')}
                  />
                }
              >
                <VStack spacing={{ base: 4, sm: 6 }}>
                  <Text
                    color={useColorModeValue('gray.500', 'gray.400')}
                    fontWeight={'800'}
                    fontSize={'2xl'}
                  >
                    Производитель: ООО "Кормо-Стандарт"
                  </Text>

                  <Text
                    color={useColorModeValue('gray.500', 'gray.400')}
                    fontWeight={'600'}
                    fontSize={'xl'}
                  >
                    Упаковка: полипропиленовый мешок 35кг
                  </Text>
                  <Text
                    color={useColorModeValue('gray.500', 'gray.400')}
                    fontWeight={'600'}
                    fontSize={'xl'}
                  >
                    Форм-фактор: гранула 2мм
                  </Text>
                  <Text
                    color={useColorModeValue('gray.500', 'gray.400')}
                    fontWeight={'600'}
                    fontSize={'xl'}
                  >
                    Стандарт: ГОСТ 18221-99
                  </Text>

                  <Text fontSize={'lg'}>
                    Условия хранения: чистое, сухое, хорошо проветриваемое
                    помещение, не зараженное вредителями хлебных запасов
                  </Text>
                  <Text fontSize={'lg'}>
                    Состав: пшеница, кукуруза, отруби пшеничные, жмых
                    подсолнечный, мука мясо-костная, дрожжи кормовые, мел
                    кормовой, ракушка, масло подсолнечное, премикс, биодобавки
                  </Text>
                  <Text fontSize={'lg'}>
                    Показатели качества: обм.эн-258,00 Ккал\100г; сырой
                    протеин-15,83%; сырая клетчатка-4,63%; лизин-0,71%;
                    метионин+цистин-0,61%; кальций-3,22%; фосфор-0,81%; хлорид
                    натрия-0,22%
                  </Text>
                  <Text fontSize={'lg'}>
                    Указания по применению: скармливать как единственный корм,
                    норма расхода 120-130г на голову в сутки, раздавать 2 раза в
                    сутки, доступ к свежей воде постоянный
                  </Text>
                  <Button
                    color={'yellow.500'}
                    m={'3'}
                    fontSize={'35'}
                    href={'/pricepage'}
                    cursor={'pointer'}
                    as={'a'}
                  >
                    Узнать цену
                  </Button>
                </VStack>
              </Stack>
            </Stack>
          </SimpleGrid>
        </Container>
      </Container>
      <Footer />
    </ChakraProvider>
  );
};

export default PK12Page;
