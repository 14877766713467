import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

//Main Pages
import MainPage from '../src/pages/MainPages/MainPage.tsx';
import VacancyPage from './pages/MainPages/VacancyPage.tsx';
import NotFound from './pages/MainPages/NotFound.tsx';
import PricePage from './pages/MainPages/PricePage.tsx';

//Category Pages
import CerealsPage from '../src/pages/CategoryPages/СerealsPage.tsx';
import CompoundFeedPage from '../src/pages/CategoryPages/CompoundFeedPage.tsx';
import BranPage from '../src/pages/CategoryPages/BranPage.tsx';
import ExtrudedPage from '../src/pages/CategoryPages/ExtrudedPage.tsx';
import BMVKPage from '../src/pages/CategoryPages/BMVKPage.tsx';
import UniversalPage from './pages/CategoryPages/CompoundUniversalPage.tsx';

//SubCategory Pages

//Animal Pages
import ForBirdPage from '../src/pages/CategoryPages/SubCategoryPages/AnimalPages/ForBirdPage.tsx';
import ForPigPage from '../src/pages/CategoryPages/SubCategoryPages/AnimalPages/ForPigPage.tsx';
import ForCowPage from '../src/pages/CategoryPages/SubCategoryPages/AnimalPages/ForCowPage.tsx';
import ForRabbitPage from '../src/pages/CategoryPages/SubCategoryPages/AnimalPages/ForRabbitPage.tsx';
import ForDogPage from '../src/pages/CategoryPages/SubCategoryPages/AnimalPages/ForDogPage.tsx';

//Bran Pages
import BranPushPage from './pages/CategoryPages/SubCategoryPages/BranPages/BranPushPage.tsx';
import BranGranPage from './pages/CategoryPages/SubCategoryPages/BranPages/BranGranPage.tsx';

//Des Pages
import BMVKDesPage from './pages/DesPages/BMVKDesPage.tsx';
import BranPushDesPage from './pages/DesPages/BranPushDesPage.tsx';
import BranGranDesPage from './pages/DesPages/BranGranDesPage.tsx';
import CowKormPage from './pages/DesPages/CowKormPage.tsx';
import ExtrudedKormPage from './pages/DesPages/ExtrudedKormPage.tsx';
import KK60Page from './pages/DesPages/KK60Page.tsx';
import KKDogDesPage from './pages/DesPages/KKDogDesPage.tsx';
import KormosmesPage from './pages/DesPages/KormosmesPage.tsx';
import PigUniversalPage from './pages/DesPages/PigUniversalPage.tsx';
import PK2Page from './pages/DesPages/PK2Page.tsx';
import PK5Page from './pages/DesPages/PK5Page.tsx';
import PK6Page from './pages/DesPages/PK6Page.tsx';
import PK12Page from './pages/DesPages/PK12Page.tsx';
import PKPage from './pages/DesPages/PKPage.tsx';
import PZK90Page from './pages/DesPages/PZK90Page.tsx';
import RakushkaPage from './pages/DesPages/RakushkaPage.tsx';
import SK4Page from './pages/DesPages/SK4Page.tsx';
import LoginPage from './pages/MainPages/LoginPage.jsx';
import AdminPage from '../src/pages/MainPages/AdminPage.tsx';
import PrivateRoute from './utils/router/PrivateRoute.jsx';
function App() {
  return (
    <Router>
      <Routes>
        //main
        <Route exact path="/" element={<MainPage />} />
        <Route exact path="/cereals" element={<CerealsPage />} />
        <Route exact path="/compoundFeed" element={<CompoundFeedPage />} />
        <Route exact path="/bran" element={<BranPage />} />
        <Route exact path="/extruded" element={<ExtrudedPage />} />
        <Route exact path="/bmvk" element={<BMVKPage />} />
        <Route exact path="/vacancy" element={<VacancyPage />} />
        <Route exact path="/pricepage" element={<PricePage />} />
        //CompoundFeedPage category
        <Route exact path="/compoundforBird" element={<ForBirdPage />} />
        <Route exact path="/compoundforPig" element={<ForPigPage />} />
        <Route exact path="/compoundforCow" element={<ForCowPage />} />
        <Route exact path="/compoundforRabbit" element={<ForRabbitPage />} />
        <Route exact path="/compoundforDog" element={<ForDogPage />} />
        <Route exact path="/compoundUniversal" element={<UniversalPage />} />
        //Bran category
        <Route exact path="/branPush" element={<BranPushPage />} />
        <Route exact path="/branGran" element={<BranGranPage />} />
        //korma chicken
        <Route exact path="/pk5" element={<PK5Page />} />
        <Route exact path="/pk6" element={<PK6Page />} />
        <Route exact path="/pk2" element={<PK2Page />} />
        <Route exact path="/pk12" element={<PK12Page />} />
        <Route exact path="/pk" element={<PKPage />} />
        <Route exact path="/rakushka" element={<RakushkaPage />} />
        //korma pig
        <Route exact path="/kormosmes" element={<KormosmesPage />} />
        <Route exact path="/piguniversal" element={<PigUniversalPage />} />
        <Route exact path="/sk4" element={<SK4Page />} />
        //korma cow
        <Route exact path="/cowkorm" element={<CowKormPage />} />
        <Route exact path="/kk60" element={<KK60Page />} />
        //korma rabbit
        <Route exact path="/pzk90" element={<PZK90Page />} />
        //korma dogs
        <Route exact path="/kkdog" element={<KKDogDesPage />} />
        //korma extruded
        <Route exact path="/extrudedkorm" element={<ExtrudedKormPage />} />
        //bran
        <Route
          exact
          path="/branpushdescription"
          element={<BranPushDesPage />}
        />
        <Route
          exact
          path="/brangrandescription"
          element={<BranGranDesPage />}
        />
        //bmvk
        <Route exact path="/bmvkdes" element={<BMVKDesPage />} />
        <Route path="*" element={<NotFound />} />
        <Route element={<PrivateRoute />}>
          <Route exact path="/products" element={<AdminPage />} />
        </Route>
        <Route exact path="/login" element={<LoginPage />} />
      </Routes>
    </Router>
  );
}

export default App;
