import React, { useState, useEffect } from 'react';
import { ChakraProvider, Button } from '@chakra-ui/react';
import theme from '../../../../theme';
import { SimpleGrid, Container } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';

import Header from '../../../../components/Header';
import imageOne from '../../../../assets/img/kur1.jpg';
import imageTwo from '../../../../assets/img/kur2.jpg';
import imageThree from '../../../../assets/img/kur3.jpg';
import imageFour from '../../../../assets/img/kur4.jpg';
import imageFive from '../../../../assets/img/kur5.jpg';

import imageSix from '../../../../assets/img/rakushka.jpg';

import CardProduct from '../../../../components/CardProduct.js';
import Footer from '../../../../components/Footer';
import DividerHeader from '../../../../components/DividerHeader';
import ScrollToTop from '../../../../components/ScrollToTop';


import Loading from '../../../../components/Loading';
import axios from 'axios';
import serverURL from '../../../../services/serverURL.jsx';

const BirdPage = () => {
  const [forbird, setForbird] = useState<any>(null);

  // Функция для выполнения GET-запроса к серверу и получения данных о товарах
  const fetchForbird = async () => {
    try {
      const response = await axios.get(`${serverURL}/forbird`);
      setForbird(response.data);
    } catch (error) {
      console.error('Ошибка при получении данных:', error);
    }
  };

  useEffect(() => {
    fetchForbird();
  }, []);

  if (!forbird) {
    return <Loading></Loading>;
  }
  return (
    <ChakraProvider theme={theme}>
      <Header />
      <Button m={'3'} href={'/compoundFeed'} cursor={'pointer'} as={'a'}>
        <ArrowBackIcon boxSize={10} color={'yellow.500'} />
      </Button>
      <Container maxW={'full'} h={'480vh'} centerContent>
        <DividerHeader name="Для сельхоз-птицы" id="contacts" />

        <SimpleGrid columns={[1, 1, 2, 2, 3, 4]} spacing="40px">
          <CardProduct
            title="ПК-5"
            optionTitle="Для цыплят бройлеров"
            subtitle={`Мешки ${forbird[0].weight}`}
            description="Подробнее..."
            price={`${forbird[0].price}`}
            image={imageOne}
            href={'/pk5'}
          />
          <CardProduct
            title="ПК-6"
            optionTitle="Для подрощенных цыплят бройлеров"
            subtitle={`Мешки ${forbird[1].weight}`}
            description="Подробнее..."
            price={`${forbird[1].price}`}
            image={imageTwo}
            href={'/pk6'}
          />
          <CardProduct
            title="ПК-2"
            optionTitle="Для цыплят"
            subtitle={`Мешки ${forbird[2].weight}`}
            description="Подробнее..."
            price={`${forbird[2].price}`}
            image={imageThree}
            href={'/pk2'}
          />
          <CardProduct
            title="ПК-1-2"
            optionTitle="Для взрослых кур несушек"
            subtitle={`Мешки ${forbird[3].weight}`}
            description="Подробнее..."
            price={`${forbird[3].price}`}
            image={imageFour}
            href={'/pk12'}
          />
          <CardProduct
            title="ПК "
            optionTitle="Универсальный для всех видов птиц"
            subtitle={`Мешки ${forbird[4].weight}`}
            description="Подробнее..."
            price={`${forbird[4].price}`}
            image={imageFive}
            href={'/pk'}
          />
          <CardProduct
            title="Ракушка"
            subtitle={`Мешки ${forbird[5].weight}`}
            description="Подробнее..."
            price={`${forbird[5].price}`}
            image={imageSix}
            href={'/rakushka'}
          />
        </SimpleGrid>
      </Container>
      <ScrollToTop />
      <Footer />
    </ChakraProvider>
  );
};

export default BirdPage;
