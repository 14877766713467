import React, { useRef } from 'react';

import ComponentToPrint from '../components/TableDash.tsx';

import { Button, Center } from '@chakra-ui/react';
import ReactToPrint from 'react-to-print';

export default function PrintComponent() {
  let componentRef = useRef();

  return (
    <>
      <div>
        {/* button to trigger printing of target component */}
        <Center>
          <ReactToPrint
            trigger={() => (
              <Button
                m={16}
                fontSize={'22'}
                cursor={'pointer'}
                color={'yellow.500'}
              >
                Нажмите чтобы распечатать в PDF
              </Button>
            )}
            content={() => componentRef}
          />

          {/* component to be printed */}
          <div style={{ display: 'none' }}>
            <ComponentToPrint ref={el => (componentRef = el)} />
          </div>
        </Center>
      </div>
    </>
  );
}
