import React, { useState, useEffect } from 'react';
import { ChakraProvider, Button } from '@chakra-ui/react';
import theme from '../../../../theme';
import { SimpleGrid, Container } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';

import Header from '../../../../components/Header';

import imageOne from '../../../../assets/img/Bran1.webp';

import CardProduct from '../../../../components/CardProduct.js';
import Footer from '../../../../components/Footer';
import DividerHeader from '../../../../components/DividerHeader';

import Loading from '../../../../components/Loading';
import axios from 'axios';
import serverURL from '../../../../services/serverURL.jsx';

const BranPushPage = () => {
  const [forbranpush, setForbranpush] = useState<any>(null);

  // Функция для выполнения GET-запроса к серверу и получения данных о товарах
  const fetchForbranpush = async () => {
    try {
      const response = await axios.get(`${serverURL}/forbranpush`);
      setForbranpush(response.data);
    } catch (error) {
      console.error('Ошибка при получении данных:', error);
    }
  };

  useEffect(() => {
    fetchForbranpush();
  }, []);

  if (!forbranpush) {
    return <Loading></Loading>;
  }
  return (
    <ChakraProvider theme={theme}>
      <Header />
      <Button m={'3'} href={'/bran'} cursor={'pointer'} as={'a'}>
        <ArrowBackIcon boxSize={10} color={'yellow.500'} />
      </Button>
      <Container maxW={'full'} h={'230vh'} centerContent>
        <DividerHeader name="Отруби пушистые" id="contacts" />

        <SimpleGrid columns={[1, 1, 1, 1, 1, 1]} spacing="40px">
          <CardProduct
            title="Отруби пушистые"
            optionTitle=""
            subtitle={`Мешки ${forbranpush.weight}`}
            description="Подробнее..."
            price={forbranpush.price}
            image={imageOne}
            href={'/branpushdescription'}
          />
        </SimpleGrid>
      </Container>
      <Footer />
    </ChakraProvider>
  );
};

export default BranPushPage;
