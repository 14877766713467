import {createSlice} from "@reduxjs/toolkit"
const initialState = {
    isLogin: false,
}

export const authSlice = createSlice({
    name:'auth',
    initialState,
    reducers: {
        login_state(state,action) {
            state.isLogin = true;
        }
    }
})

export const {login_state} = authSlice.actions
export default authSlice.reducer