import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Heading,
  Text,
} from '@chakra-ui/react';
import { Navigate, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAppDispath } from '../../utils/hook';
import serverURL from '../../services/serverURL.jsx';
import { login_state } from '../../store/slice/auth';

const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const dispatch = useAppDispath();
  // Определите функцию для отправки запроса
  const login = (username, password) => {
    axios
      .post(
        `${serverURL}/login`,
        { username, password },
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then(async response => {
        if (response.status === 200) {
          // Аутентификация прошла успешно
          console.log('Аутентификация прошла успешно');
          try {
            await dispatch(
              login_state({
                isLogin: true,
              })
            );
            navigate('/products');
          } catch (err) {
            return err;
          }
        } else {
          // Аутентификация не удалась, обработайте ошибку

          setError('Неверное имя пользователя или пароль');
        }
      })
      .catch(error => {
        console.error('Произошла ошибка:', error);
        setError('Неверное имя пользователя или пароль');
      });
  };

  const handleLogin = () => {
    login(username, password);
  };

  return (
    <Box
      maxW="400px"
      mx="auto"
      mt="20"
      p="4"
      borderWidth="1px"
      borderRadius="md"
      boxShadow="md"
    >
      <Heading as="h2" size="lg" textAlign="center" mb="4">
        Вход
      </Heading>
      <FormControl id="username" isRequired>
        <FormLabel>Имя пользователя</FormLabel>
        <Input
          type="text"
          value={username}
          onChange={e => setUsername(e.target.value)}
        />
      </FormControl>
      <FormControl id="password" isRequired mt="4">
        <FormLabel>Пароль</FormLabel>
        <Input
          type="password"
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
      </FormControl>
      <Button
        bgGradient="linear(to-r, yellow.400, yellow.500, yellow.600)"
        color="white"
        variant="solid"
        colorScheme="yellow"
        size="lg"
        mt="6"
        ml="130"
        onClick={handleLogin}
        isFullWidth
      >
        Войти
      </Button>
      <Text mt="4" textAlign="center" color="red.500">
        {error}
      </Text>
    </Box>
  );
};

export default LoginPage;
