import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import theme from '../../theme';

import { ArrowBackIcon } from '@chakra-ui/icons';

import Header from '../../components/Header';

import imageOne from '../../assets/img/krs.jpg';

import * as react from '@chakra-ui/react';

import Footer from '../../components/Footer';

const CowKormPage = () => {
  return (
    <ChakraProvider theme={theme}>
      <Header />
      <react.Button
        m={'3'}
        href={'/compoundforCow'}
        cursor={'pointer'}
        as={'a'}
      >
        <ArrowBackIcon boxSize={10} color={'yellow.500'} />
      </react.Button>
      <react.Container maxW={'full'} h={'280vh'} centerContent>
        <react.Container maxW={'7xl'}>
          <react.SimpleGrid
            columns={{ base: 1, lg: 2 }}
            spacing={{ base: 8, md: 10 }}
            py={{ base: 18, md: 24 }}
          >
            <react.Flex>
              <react.Image
                rounded={'md'}
                alt={'product image'}
                src={imageOne}
                fit={'cover'}
                align={'center'}
                w={'100%'}
                h={{ base: '100%', sm: '400px', lg: '500px' }}
              />
            </react.Flex>
            <react.Stack spacing={{ base: 6, md: 10 }}>
              <react.Box as={'header'}>
                <react.Heading
                  lineHeight={1.1}
                  fontWeight={600}
                  color={'yellow.500'}
                  fontSize={{ base: '2xl', sm: '4xl', lg: '5xl' }}
                >
                  Комбикорм для откорма КРС в стойловый период
                </react.Heading>
              </react.Box>

              <react.Stack
                spacing={{ base: 4, sm: 6 }}
                direction={'column'}
                divider={
                  <react.StackDivider
                    borderColor={react.useColorModeValue(
                      'gray.200',
                      'gray.600'
                    )}
                  />
                }
              >
                <react.VStack spacing={{ base: 4, sm: 6 }}>
                  <react.Text
                    color={react.useColorModeValue('gray.500', 'gray.400')}
                    fontWeight={'800'}
                    fontSize={'2xl'}
                  >
                    Производитель: ООО "Кормо-Стандарт"
                  </react.Text>

                  <react.Text
                    color={react.useColorModeValue('gray.500', 'gray.400')}
                    fontWeight={'600'}
                    fontSize={'xl'}
                  >
                    Упаковка: полипропиленовый мешок 35кг
                  </react.Text>
                  <react.Text
                    color={react.useColorModeValue('gray.500', 'gray.400')}
                    fontWeight={'600'}
                    fontSize={'xl'}
                  >
                    Форм-фактор: гранула 6мм
                  </react.Text>
                  <react.Text
                    color={react.useColorModeValue('gray.500', 'gray.400')}
                    fontWeight={'600'}
                    fontSize={'xl'}
                  >
                    Стандарт: ГОСТ 9268-90
                  </react.Text>

                  <react.Text fontSize={'lg'}>
                    Условия хранения: чистое, сухое, хорошо проветриваемое
                    помещение, не зараженное вредителями хлебных запасов
                  </react.Text>
                  <react.Text fontSize={'lg'}>
                    Состав: овес, ячмень, отруби пшеничные,пшеница, жмых
                    подсолнечный, дрожжи кормовые, соль поваренная, мел
                    кормовой, премикс
                  </react.Text>
                  <react.Text fontSize={'lg'}>
                    Показатели качества: КЕ-0,90/кг; сырой протеин-15,55%;
                    кальций-0,61%; фосфор-0,76%; хлорид натрия-1,18%
                  </react.Text>
                  <react.Text fontSize={'lg'}>
                    Указания по применению: раздавать 2-3 раза в сутки
                  </react.Text>
                  <react.Button
                    color={'yellow.500'}
                    m={'3'}
                    fontSize={'35'}
                    href={'/pricepage'}
                    cursor={'pointer'}
                    as={'a'}
                  >
                    Узнать цену
                  </react.Button>
                </react.VStack>
              </react.Stack>
            </react.Stack>
          </react.SimpleGrid>
        </react.Container>
      </react.Container>
      <Footer />
    </ChakraProvider>
  );
};

export default CowKormPage;
