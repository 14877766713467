import React from 'react';
import {
  ChakraProvider,
  Button,
  Heading,
  VStack,
  Tooltip,
} from '@chakra-ui/react';
import theme from '../../theme';
import {
  SimpleGrid,
  Container,
  Text,
  Box,
  useColorModeValue,
  Center,
  Image,
} from '@chakra-ui/react';
import { ArrowBackIcon, PhoneIcon } from '@chakra-ui/icons';

import Header from '../../components/Header';

import imageOne from '../../assets/img/rab1.png';
import imageTwo from '../../assets/img/rab2.png';

import Footer from '../../components/Footer';
import DividerHeader from '../../components/DividerHeader';

const VacancyPage = () => {
  return (
    <ChakraProvider theme={theme}>
      <Header />
      <Button m={'3'} href={'/'} cursor={'pointer'} as={'a'}>
        <ArrowBackIcon boxSize={10} color={'yellow.500'} />
      </Button>
      <Container maxW={'full'} h={'280vh'} centerContent>
        <DividerHeader name="Вакансии" id="contacts" />

        <Center>
          <SimpleGrid columns={[1, 1, 1, 2]} spacing={10}>
            <Box
              role={'group'}
              p={6}
              w={'full'}
              bg={useColorModeValue('white', 'gray.800')}
              boxShadow={'2xl'}
              rounded={'lg'}
              pos={'relative'}
              zIndex={1}
            >
              <VStack spacing={{ base: 4, sm: 6 }}>
                <Heading
                  lineHeight={1.1}
                  fontWeight={600}
                  color={'yellow.500'}
                  fontSize={{ base: '2md', sm: 'xl', lg: '2xl' }}
                >
                  Грузчик-фасовщик
                </Heading>

                <Image
                  rounded={'lg'}
                  height={290}
                  width={282}
                  objectFit={'cover'}
                  src={imageOne}
                />

                <Text
                  color={useColorModeValue('gray.500', 'gray.400')}
                  fontWeight={'600'}
                  fontSize={'3xl'}
                >
                  Условия работы:
                </Text>

                <Text fontSize={'xl'}>
                  Требуются грузчики фасовщики на фасовку отрубей и зерновых
                </Text>
                <Text fontSize={'xl'}>
                  Работа в холодном складе , фасовка вручную и на шнеке
                </Text>
                <Text fontSize={'xl'}>Опыт работы не требуется</Text>

                <Text
                  color={useColorModeValue('gray.500', 'gray.400')}
                  fontWeight={'600'}
                  fontSize={'3xl'}
                >
                  Условия оплаты:
                </Text>

                <Text fontSize={'xl'}>Ежедневно ,сдельно</Text>
                <Text fontSize={'xl'}>
                  Фасовка отдельно , погрузка в машины отдельно
                </Text>

                <Button
                  bg={'green.400'}
                  rounded={'full'}
                  color={'white'}
                  pl={'30px'}
                  pr={'30px'}
                  _hover={{ bg: 'green.500' }}
                >
                  <Tooltip label="Phone number" fontSize="md">
                    <PhoneIcon />
                  </Tooltip>
                  <a href="tel:+79659709820"> +7 965 970 98 20 </a>
                </Button>
              </VStack>
            </Box>

            <Box
              role={'group'}
              p={6}
              w={'full'}
              bg={useColorModeValue('white', 'gray.800')}
              boxShadow={'2xl'}
              rounded={'lg'}
              pos={'relative'}
              zIndex={1}
            >
              <VStack spacing={{ base: 4, sm: 6 }}>
                <Heading
                  lineHeight={1.1}
                  fontWeight={600}
                  color={'yellow.500'}
                  fontSize={{ base: '2md', sm: 'xl', lg: '2xl' }}
                >
                  Оператор на линию грануляции
                </Heading>

                <Image
                  rounded={'lg'}
                  height={290}
                  width={282}
                  objectFit={'cover'}
                  src={imageTwo}
                />

                <Text
                  color={useColorModeValue('gray.500', 'gray.400')}
                  fontWeight={'600'}
                  fontSize={'3xl'}
                >
                  Условия работы:
                </Text>

                <Text fontSize={'xl'}>В теплом помещении</Text>
                <Text fontSize={'xl'}>
                  График работы 5 дней в неделю по будням
                </Text>
                <Text fontSize={'xl'}>Технический опыт приветствуется</Text>

                <Text
                  color={useColorModeValue('gray.500', 'gray.400')}
                  fontWeight={'600'}
                  fontSize={'3xl'}
                >
                  Условия оплаты:
                </Text>

                <Text fontSize={'xl'}>
                  {' '}
                  Оплата 2 раза в месяц. Оклад 50 тыс.руб.{' '}
                </Text>
                <Text fontSize={'xl'}>
                  Задержки и выход в выходные оплачивается отдельно
                </Text>

                <Button
                  bg={'green.400'}
                  rounded={'full'}
                  color={'white'}
                  pl={'30px'}
                  pr={'30px'}
                  _hover={{ bg: 'green.500' }}
                >
                  <Tooltip label="Phone number" fontSize="md">
                    <PhoneIcon />
                  </Tooltip>
                  <a href="tel:+79659709820"> +7 965 970 98 20 </a>
                </Button>
              </VStack>
            </Box>
          </SimpleGrid>
        </Center>
      </Container>
      <Footer />
    </ChakraProvider>
  );
};

export default VacancyPage;
