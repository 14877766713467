import {
  Box,
  Center,
  useColorModeValue,
  Heading,
  Text,
  Stack,
  SimpleGrid,
  Container,
} from '@chakra-ui/react';
import React from 'react';

const Schedule = () => {
  let today = new Date();
  const defaultColor = 'white';
  const textColor = 'yellow.500';
  const selectColor = 'green.200';
  const week = [
    defaultColor,
    defaultColor,
    defaultColor,
    defaultColor,
    defaultColor,
    defaultColor,
    defaultColor,
  ];
  const weekText = [
    textColor,
    textColor,
    textColor,
    textColor,
    textColor,
    textColor,
    textColor,
    textColor,
  ];

  const currentDay = today.getDay();
  week[currentDay] = selectColor;
  weekText[currentDay] = 'white';
  return (
    <Container maxW={'full'} centerContent>
      <SimpleGrid columns={[1, 1, 2, 2, 3, 7]} spacing="15px">
        <Center py={12}>
          <Box
            role={'group'}
            p={6}
            maxW={'330px'}
            w={'full'}
            bg={useColorModeValue(week[1], 'gray.800')}
            boxShadow={'2xl'}
            rounded={'lg'}
            pos={'relative'}
            zIndex={1}
          >
            <Stack pt={3} align={'center'}>
              <Heading
                color={weekText[1]}
                fontSize={'2xl'}
                fontFamily={'body'}
                fontWeight={500}
              >
                Понедельник
              </Heading>
              <Heading
                color={'gray.500'}
                fontSize={'2xl'}
                fontFamily={'body'}
                fontWeight={500}
              >
                9:00 - 18:00
              </Heading>
              <Stack direction={'row'} align={'center'}>
                <Text fontWeight={800} fontSize={'xl'}></Text>
              </Stack>
            </Stack>
          </Box>
        </Center>
        <Center py={12}>
          <Box
            role={'group'}
            p={6}
            maxW={'330px'}
            w={'full'}
            bg={useColorModeValue(week[2], 'gray.800')}
            boxShadow={'2xl'}
            rounded={'lg'}
            pos={'relative'}
            zIndex={1}
          >
            <Stack pt={3} align={'center'}>
              <Heading
                color={weekText[2]}
                fontSize={'2xl'}
                fontFamily={'body'}
                fontWeight={500}
              >
                Вторник
              </Heading>
              <Heading
                color={'gray.500'}
                fontSize={'2xl'}
                fontFamily={'body'}
                fontWeight={500}
              >
                9:00 - 18:00
              </Heading>
              <Stack direction={'row'} align={'center'}>
                <Text fontWeight={800} fontSize={'xl'}></Text>
              </Stack>
            </Stack>
          </Box>
        </Center>
        <Center py={12}>
          <Box
            role={'group'}
            p={6}
            maxW={'330px'}
            w={'full'}
            bg={useColorModeValue(week[3], 'gray.800')}
            boxShadow={'2xl'}
            rounded={'lg'}
            pos={'relative'}
            zIndex={1}
          >
            <Stack pt={3} align={'center'}>
              <Heading
                color={weekText[3]}
                fontSize={'2xl'}
                fontFamily={'body'}
                fontWeight={500}
              >
                Среда
              </Heading>
              <Heading
                color={'gray.500'}
                fontSize={'2xl'}
                fontFamily={'body'}
                fontWeight={500}
              >
                9:00 - 18:00
              </Heading>
              <Stack direction={'row'} align={'center'}>
                <Text fontWeight={800} fontSize={'xl'}></Text>
              </Stack>
            </Stack>
          </Box>
        </Center>
        <Center py={12}>
          <Box
            role={'group'}
            p={6}
            maxW={'330px'}
            w={'full'}
            bg={useColorModeValue(week[4], 'gray.800')}
            boxShadow={'2xl'}
            rounded={'lg'}
            pos={'relative'}
            zIndex={1}
          >
            <Stack pt={3} align={'center'}>
              <Heading
                color={weekText[4]}
                fontSize={'2xl'}
                fontFamily={'body'}
                fontWeight={500}
              >
                Четверг
              </Heading>
              <Heading
                color={'gray.500'}
                fontSize={'2xl'}
                fontFamily={'body'}
                fontWeight={500}
              >
                9:00 - 18:00
              </Heading>
              <Stack direction={'row'} align={'center'}>
                <Text fontWeight={800} fontSize={'xl'}></Text>
              </Stack>
            </Stack>
          </Box>
        </Center>
        <Center py={12}>
          <Box
            role={'group'}
            p={6}
            maxW={'330px'}
            w={'full'}
            bg={useColorModeValue(week[5], 'gray.800')}
            boxShadow={'2xl'}
            rounded={'lg'}
            pos={'relative'}
            zIndex={1}
          >
            <Stack pt={3} align={'center'}>
              <Heading
                color={weekText[5]}
                fontSize={'2xl'}
                fontFamily={'body'}
                fontWeight={500}
              >
                Пятница
              </Heading>
              <Heading
                color={'gray.500'}
                fontSize={'2xl'}
                fontFamily={'body'}
                fontWeight={500}
              >
                9:00 - 18:00
              </Heading>
              <Stack direction={'row'} align={'center'}>
                <Text fontWeight={800} fontSize={'xl'}></Text>
              </Stack>
            </Stack>
          </Box>
        </Center>
        <Center py={12}>
          <Box
            role={'group'}
            p={6}
            maxW={'330px'}
            w={'full'}
            bg={useColorModeValue(week[6], 'gray.800')}
            boxShadow={'2xl'}
            rounded={'lg'}
            pos={'relative'}
            zIndex={1}
          >
            <Stack pt={3} align={'center'}>
              <Heading
                color={'gray.400'}
                fontSize={'2xl'}
                fontFamily={'body'}
                fontWeight={500}
              >
                Суббота
              </Heading>
              <Heading
                color={'gray.500'}
                fontSize={'2xl'}
                fontFamily={'body'}
                fontWeight={500}
              >
                Выходной
              </Heading>
              <Stack direction={'row'} align={'center'}>
                <Text fontWeight={800} fontSize={'xl'}></Text>
              </Stack>
            </Stack>
          </Box>
        </Center>
        <Center py={12}>
          <Box
            role={'group'}
            p={6}
            maxW={'330px'}
            w={'full'}
            bg={useColorModeValue(week[0], 'gray.800')}
            boxShadow={'2xl'}
            rounded={'lg'}
            pos={'relative'}
            zIndex={1}
          >
            <Stack pt={3} align={'center'}>
              <Heading
                color={'gray.400'}
                fontSize={'2xl'}
                fontFamily={'body'}
                fontWeight={500}
              >
                Воскресенье
              </Heading>
              <Heading
                color={'gray.500'}
                fontSize={'2xl'}
                fontFamily={'body'}
                fontWeight={500}
              >
                Выходной
              </Heading>
              <Stack direction={'row'} align={'center'}>
                <Text fontWeight={800} fontSize={'xl'}></Text>
              </Stack>
            </Stack>
          </Box>
        </Center>
      </SimpleGrid>
    </Container>
  );
};
export default Schedule;
