import React from 'react';
import { Box, Center, VStack } from '@chakra-ui/react';

const DividerHeader = (props: { id: string | undefined; name: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; }) => {
  return (
    <VStack spacing={3} align="stretch" id={props.id}>
      <Box h="10px"></Box>
      <Box h="30px">
        <Center
          as="span"
          w="100%"
          fontWeight="semibold"
          letterSpacing="wide"
          fontSize="3xl"
          color="gray.500"
        >
          {props.name}
        </Center>
      </Box>
      <Box h="10px"></Box>
    </VStack>
  );
};
export default DividerHeader;
